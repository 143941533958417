<!-- 双色球周四基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>双 色 球 周 四 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="11" class="ranks_b">一区</th>
                        <th colspan="11" class="ranks_b">二区</th>
                        <th colspan="11" class="ranks_b">三区</th>
                        <th colspan="16" class="ranks_b">蓝球</th>
                        <th rowspan="2" class="ranks_a1">和值</th>
                        <th rowspan="2" class="ranks_a1">跨度</th>
                        <th rowspan="2" class="ranks_a1">AC值</th>
                        <th rowspan="2" class="ranks_b">奇偶</th>
                        <th rowspan="2" class="ranks_b">三区比</th>
                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item , i) in 33" :key="i">{{item}}</th>
                        <th class="ranks_d" v-for="(item , i) in 16" :key="i">{{item}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in ssq" :key='x'>
                        <td>{{item2.qihao}}</td>
                        <!-- <td>{{item2.xq}}</td> -->
                        <td class="ssq-red" v-for="(item ,i) in red" :key='i'>
                            <div
                                :class="item == item2.data[0]? 'red' : ''|| item == item2.data[1]? 'red' : '' || item == item2.data[2]? 'red' : ''|| item == item2.data[3]? 'red' : ''|| item == item2.data[4]? 'red' : ''|| item == item2.data[5]? 'red' : ''">
                                {{item == item2.data[0] ?item:'' || item == item2.data[1]
                                ?item:'' || item == item2.data[2] ?item:'' || item == item2.data[3] ?item:'' || item ==
                                item2.data[4] ?item:'' || item == item2.data[5] ?item:'' }}
                            </div>
                        </td>
                        <td class="ssq-lan" 
                        v-for="(item3 ,i) in lan" :key='i'>
                            <div :class="item3 == item2.data[6]? 'lan' : ''" >
                                {{item3 == item2.data[6] ?item3:''}}
                            </div></td>
                        <td>{{item2.data[0]+item2.data[1]+item2.data[2]+item2.data[3]+item2.data[4]+item2.data[5]}}</td>
                        <td>{{item2.data[5]-item2.data[0]}}</td>
                        <td>{{ac[x]}}</td>
                        <td>{{item2.data[0]%2+item2.data[1]%2+item2.data[2]%2+item2.data[3]%2+item2.data[4]%2+item2.data[5]%2}}:{{6-(item2.data[0]%2+item2.data[1]%2+item2.data[2]%2+item2.data[3]%2+item2.data[4]%2+item2.data[5]%2)}}
                        </td>
                        <td>{{item2.sqb[0]}}:{{item2.sqb[1]}}:{{item2.sqb[2]}}</td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 54" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 54" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 54" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                red: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
                lan: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                ac:[],
                // ssq: [
                //     { qihao: "20210121", xq: "2", data: [3, 9, 22, 24, 25, 29, 2], ac: 5, sqb: [2, 1, 3] },
                //     { qihao: "20210122", xq: "4", data: [2, 4, 19, 20, 21, 23, 4], ac: 4, sqb: [2, 3, 1] },
                //     { qihao: "20210123", xq: "7", data: [6, 7, 15, 23, 24, 25, 1], ac: 7, sqb: [2, 1, 3] },
                //     { qihao: "20210124", xq: "2", data: [1, 3, 18, 24, 27, 28, 12], ac: 9, sqb: [2, 1, 3] },
                //     { qihao: "20210125", xq: "4", data: [7, 9, 20, 23, 26, 29, 10], ac: 10, sqb: [2, 1, 3] },
                //     { qihao: "20210121", xq: "2", data: [3, 9, 22, 24, 25, 29, 2], ac: 5, sqb: [2, 1, 3] },
                //     { qihao: "20210122", xq: "4", data: [2, 4, 19, 20, 21, 23, 4], ac: 4, sqb: [2, 3, 1] },
                //     { qihao: "20210123", xq: "7", data: [6, 7, 15, 23, 24, 25, 1], ac: 7, sqb: [2, 1, 3] },
                //     { qihao: "20210124", xq: "2", data: [1, 3, 18, 24, 27, 28, 12], ac: 9, sqb: [2, 1, 3] },
                // ]
            }
        },
        mounted() {
             this.mac()
        },
        computed: {
             ssq() {
                return this.$store.state.ssq.filter((ssq) => {
                           return ssq.xq==4
                         })
            },
          
        
        },
        methods: { // 函数体部分，js的主要逻辑控制   
          mac() {//AC值
                for (var i = 0; i < this.ssq.length; i++) {
                    var arr = []
                    for (var j = 0; j < this.ssq[i].data.length - 1; j++) {
                        for (var y = j; y < 5; y++) {
                            arr.push(Math.abs(this.ssq[i].data[j] - this.ssq[i].data[y + 1]))
                        }
                    }
                    var newArr = [];
                    for (var x = 0; x < arr.length; x++) {
                        if (newArr.indexOf(arr[x]) == -1) {
                            newArr.push(arr[x]);
                        }
                    }
                    this.ac.push((newArr.length - (6 - 1)) % 10)
                }

            },            
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 60px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .ssq-red {
        height: 23px;
        background-color: rgb(224, 191, 191);
    }

    .red {
        background-color: red;
        border-radius: 15px;
        color: #fff;
    }
    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }
    tbody .lan {
        background-color: blue;
        color: #fff;
        border-radius: 15px;
    }

    .ssq-lan {
        background-color: rgb(156, 205, 211);
    }
</style>